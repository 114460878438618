import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  // Navigate to Home
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/`);
  };

  return (
    <div className="page-404 text-center">
      <div className="container">
        <h1>404</h1>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <button type="button" className="btn btn-primary" onClick={handleClick}>
          Go to home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
