import React, {useEffect, useRef} from 'react';
import RightArrow from '../../assets/images/right-arrow.png';
import HeroBg from '../../assets/images/hero.jpg';
import { useNavigate } from 'react-router-dom';

const Hero = (data) => {
  let Button = useRef();
  let navigate = useNavigate();
  useEffect(() => {
    Button.current.addEventListener('click', function(){
      navigate(`/ContactUs`);
      window.scrollTo(0,0);
    });
  })

  return (
    <div className='hero'>
      <div className='hero-bg'><img src={HeroBg} alt='hero' /></div>
      <div className='container'>
        <div className='row m-0'>
          <div className='col-lg-6 col-12 p-0'>
            <div className='hero-heading'>
              <h2 dangerouslySetInnerHTML={{ __html: data.data.title }}/>
            </div>
          </div>
          <div className='col-lg-6 col-12 p-0'>
            <div className='hero-content'>
              <p>{data.data.shortDescription}</p>
              <button type='button' className='btn btn-primary' ref={Button}>{data.data.buttonText} <img src={RightArrow} alt='right-icon'/></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
