// gtag.js
export const initGA4 = (measurementId) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", measurementId);
};
